import { required, max } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import i18n from '../locale';

extend('required', {
	...required,
	message: i18n.t('error.thisFieldIsRequired')
});

extend('max', {
	...max,
	message: 'This field must be {length} characters or less'
});
