import Vue from 'vue';
import * as types from './mutations';

export const state = {
	lang: 'en',
	operatorID: '',
	operatorAC: '',
	groupID: '',
	domain: {},
	cms: {},
	version: '',
	currency: '',
	currencyList: [],
	exchangeRate: [],
	gameList: []
};

export const actions = {
	setLanguage({ commit }, lang) {
		commit(types.LANGUAGE, lang);
	},
	setOperatorID({ commit }, operatorID) {
		commit(types.OPERATORID, operatorID);
	},
	setOperatorAC({ commit }, operatorAC) {
		commit(types.OPERATORAC, operatorAC);
	},
	setGroupID({ commit }, groupID) {
		commit(types.GROUPID, groupID);
	},
	setDomain({ commit }, domain) {
		commit(types.DOMAIN, domain);
	},
	setCms({ commit }, cms) {
		commit(types.CMS, cms);
	},
	setVersion({ commit }, version) {
		commit(types.VERSION, version);
	},
	setCurrency({ commit }, currency) {
		commit(types.CURRENCY, currency);
	},
	setCurrencyList({ commit }, currencyList) {
		commit(types.CURRENCYLIST, currencyList);
	},
	setExchangeRate({ commit }, exchangeRate) {
		commit(types.EXCHANGERATE, exchangeRate);
	},
	setGameList({ commit },gameList){
		commit(types.GAMELIST, gameList);
	}
};

export const mutations = {
	[types.LANGUAGE](STATE, setlang) {
		state.lang = setlang;
		// 設定 Vue config 將會改變使用的語系
		Vue.config.lang = state.lang;
	},
	[types.OPERATORID](STATE, operatorID) {
		state.operatorID = operatorID;
	},
	[types.OPERATORAC](STATE, operatorAC) {
		state.operatorAC = operatorAC;
	},
	[types.GROUPID](STATE, groupID) {
		state.groupID = groupID;
	},
	[types.DOMAIN](STATE, domain){
		state.domain = domain;
	},
	[types.CMS](STATE, cms){
		state.cms = cms;
	},
	[types.VERSION](STATE, version) {
		state.version = version;
	},
	[types.CURRENCY](STATE, currency) {
		state.currency = currency;
	},
	[types.CURRENCYLIST](STATE, currencyList) {
		state.currencyList = currencyList;
	},
	[types.EXCHANGERATE](STATE, exchangeRate) {
		state.exchangeRate = exchangeRate;
	},
	[types.GAMELIST](STATE, gameList){
		state.gameList = gameList;
	}
};
