const statusCode = {
	STATUS_OK: 1,
	STATUS_NO_MATCH_DATA: 8001,
	STATUS_NOT_AUTHORIZED: 8002,
	STATUS_USER_NOT_EXISTED: 8003,
	STATUS_USER_LOCKED: 8004,
	STATUS_INCORRECT_PASSWORD: 8005,
	STATUS_OPERATOR_EXIST: 8020,
	STATUS_WORKSTATION_NOT_EXIST: 8026,
	STATUS_PRIVILEGE_NOT_EXIST: 8029,
	STATUS_GROUP_NAME_EXIST: 8030,
	STATUS_SECTION_EXIST: 8031,
	STATUS_SESSION_IS_CLOSED: 8035,
	LIMIT_EXCEEDED: 8038,
	EXPIREDATE_EXPIRED: 8040,
	LOYALTY_CREDIT_BLOCKED: 8041,
	PASSWORD_EXPIRED: 8042,
	STATUS_INCORRECT_PASSWORD_LIMIT_EXCEEDED: 8044,
	STATUS_NEED_PASSWORD: 8045,
	STATUS_PRIVILEGE_NOT_ENOUGH: 8046,
	LOYALTY_CREDIT_NOT_ENOUGH: 8047,
	NEED_OTHER_OPERSTOR: 8048,
	NEED_EXPIRED_DAY_LATER_THAN_BEFORE: 8049,
	NO_EXCHANGE_TIME: 8050,
	FAIL_BINGO_BETCOUPON_DISABLED: 8056,
	ALREADY_CANCEL_EXCHANG_GIFT: 8061,
	GENERATE_REPORT_FAILED: 8065,
	GROUP_LOTTERY_ID_NOT_SET: 8066,
	GROUP_LOTTERY_NOT_EXIST: 8067,
	GROUP_LOTTERY_DATA_NOT_EXIST: 8068,
	STATUS_GAME_HOUSE_EDGE_EXIST: 8075,
	STATUS_MEMBER_RANK_RWEARD_FOR_TABLE_EXIST: 8077,
	STATUS_CHIP_ROLLING_LEVEL_EXIST: 8078,
	STATUS_BETRULE_ID_NOT_EXIST: 8079,
	STATUS_GAME_ID_NOT_EXIST: 8080,
	STATUS_ROLE_IS_USED: 8081,
	STATUS_GAME_IS_USED: 8082,
	REDEEM_ITEM_NAME_IS_USE: 8083,
	SHOP_CATEGORY_IS_USED: 8084,
	STATUS_CHIP_SET_DETAIL_IS_USED: 8086,
	STATUS_CHIP_DEFINE_IS_USED: 8087,
	STATUS_CONFIG_GROUP_NAME_IS_USED: 8089,
	STATUS_CHIP_DENOM_IS_USED: 8090,
	STATUS_AREA_IS_USED: 8091,
	STATUS_BET_RULE_IS_USED: 8092,
	STATUS_NO_CLOSE_ACCOUNTING_EXIST: 8095,
	STATUS_CLOSED_ACCOUNTING_EXIT: 8096,
	STATUS_PIT_OR_DEALER_UNACCEPT: 8097,
	STATUS_GROUP_EXIST_OPERATOR: 8098,
	STATUS_CASHIER_UNACCEPT: 8099,
};
export default statusCode;
