<template lang='pug'>
v-row.page-header-left.app--page-header.justify-center
	v-col.d-flex.align-center.flex-column.pt-5.pb-0
		span.page-title {{ $route.meta.title || "" }}
		span.sec-font-color.sub-title {{ $route.meta.parentTitle }}
		SavePinConfirmBtn(v-if='showPinConfirmBtn()', :getRoute='route')
</template>

<script>
import SavePinConfirmBtn from '@/components/SavePinConfirmBtn';
export default {
	components: {
		SavePinConfirmBtn,
	},
	data() {
		return {
			title: 'Home',
			breadcrumbs: [],
			route: '',
		};
	},
	watch: {
		'$route.path': function (newVal) {
			this.computeBreadcrumbs();
			this.route = newVal;
			this.showPinConfirmBtn();
		},
	},
	methods: {
		computeBreadcrumbs() {
			let breadcrumbs = [
				{
					text: 'Home',
					href: '/',
					disabled: false,
				},
			];
			let appends = [];
			appends = this.$route.matched.map((item) => {
				return {
					text: item.meta.title || '',
					href: item.path || '/',
					group: item.group,
					disabled: item.path === this.$route.path,
				};
			});
			this.breadcrumbs = breadcrumbs.concat(appends);
		},
		showPinConfirmBtn() {
			if (this.route.indexOf('maintenance') !== -1) {
				return false;
			}
			if (this.$route.name === 'Dashboard') {
				return false;
			}
			return true;
		},
	},
	created() {
		this.computeBreadcrumbs();
	},
};
</script>
<style lang="stylus" scoped>
.disabled {
  color: grey;
  pointer-events: none;
  text-decoration: blink;
}

.app--page-header {
  padding-top: 45px;
}
</style>
