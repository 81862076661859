
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import vue from 'vue';
import Vuetify from 'vuetify/lib';

vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg',
	},
	theme: {
		themes: {
			light: {
				primary: '#3f51b5',
				secondary: '#b0bec5',
				accent: '#8c9eff',
				error: '#b71c1c',
			},
			dark: {
				primary: '#2a69a2',
				secondary: '#60a7d1',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
				inputFont: '#b5d6ff',
				inputBg: '#6d7e93',
			}
		},
	},
});
