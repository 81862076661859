import _ from 'lodash';
import { log } from '../util/terminal';
import jwt from 'jsonwebtoken';


/**
 * 寫入Ternimal log
 * @param {String} level
 * @param {String} message
 * @example
 * // 寫入Ternimal 自定義level log, 內容是This is log
 * logger('error','This is log');
 */
export function logger(level, message) {
	const logOptions = {
		level,
		message,
	};
	log(logOptions);
}


/**
 * 寫入Ternimal info log
 * @param {String} message
 * @example
 * // 寫入Ternimal info log, 內容是This is info log
 * infoLogger('This is info log');
 */
export function infoLogger(message) {
	const logOptions = {
		level: 'info',
		message,
	};
	log(logOptions);
}

/**
 * 寫入Ternimal error log
 * @param {String} message
 * @example
 * // 寫入Ternimal error log, 內容是This is error log
 * errorLogger('This is error log');
 */
export function errorLogger(message) {
	const logOptions = {
		level: 'error',
		message,
	};
	log(logOptions);
}

export function getAuthUser(token) {
	const user = jwt.verify(token, 'gama.net');
	if (token && !_.isUndefined(user)) {
		return user.operatorName;
	} else {
		return 'anonymity';
	}
}
