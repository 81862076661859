const getters = {
	getLanguage: state => state.lang,
	getToken: state => state.user.token,
	getOperator: state => state.user.operator,
	getOperatorDefaultRouter: state => state.user.operatorDefaultRouter,
	getOperatorDefaultRouterTab: state => state.user.operatorDefaultRouterTab,
	getOpenAutoSearch: state => state.user.openAutoSearch,
	getDomain: state => state.domain,
	getCms: state => state.cms,
	permission_routers: state => state.permission.routers,
	addRouters: state => state.permission.addRouters,
	getVersion: state => state.version,
	getCurrency: state => state.currency,
	getCurrencyList: state => state.currencyList,
	getExchangeRate: state => state.exchangeRate,
	getGameList: state => state.gameList,
	getDrawerStatus: state => state.user.drawerStatus,
	getBreadShow: state => state.user.breadShow,
	getDialogShow: state => state.user.dialogShow,
	getStatusDialog: state => state.user.statusDialog,
	// device: state => state.app.device,
	// theme: state => state.app.theme,
	// color: state => state.app.color,
};

export default getters;
