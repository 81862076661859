import fetch from '../util/fetch';

// 執行Server登入
export function operatorLogin(loginObj) {
	return fetch({
		url: '/cars/operator/login',
		method: 'post',
		data: {
			reqData: loginObj,
		},
	});
}
// 執行Admin Server登出
export function operatorLogout(reqData) {
	return fetch({
		url: '/cars/operator/logout',
		method: 'post',
		data: {
			reqData
		},
	});
}
// 存入用戶釘選資訊
export function updateOperatorPin(reqData) {
	return fetch({
		url: '/cars/authority/updateIndexPin',
		method: 'post',
		data: {
			reqData
		},
	});
}
