import winston from 'winston';

const myformat = winston.format.combine(
	winston.format.colorize(),
	winston.format.timestamp({
		format: 'YYYY-MM-DD HH:mm:ss'
	}),
	// winston.format.align(),
	winston.format.splat(),
	winston.format.simple(),
	winston.format.printf(info => `[${info.timestamp}] [${info.level}]${info.message}`)
);
const logger = winston.createLogger({
	transports: [
		new winston.transports.Console({
			format: myformat
		}),
	]
});

/**
 * 透過 Terminal 取得 MacAddress
 * @param {string} channel
 */
export function getMacAddress() {
	let result = '';
	try {
		result = window.electron.ipcRenderer.sendSync('app:macAddress');
	} catch (e) {
		logger('error', `${e}`);
	}
	return result;
}

/**
 * 透過 Terminal 寫入log
 * @param {object} data
 * log.level string (fatal > error > warn > info > debug > trace)
 * log.message string
 */
export function log(data) {
	try {
		logger.log(data);
	} catch (e) {
		/* eslint-disable no-console */
		console.log('error', e.message);
	}
}
