<template lang="pug">
	v-dialog(v-model='dialog' max-width='400px' overlay-opacity='0.9')
		template(v-slot:activator='{ on }')
			v-btn.text-capitalize.pinIcon(rounded @click='openDialog')
				v-icon(:style="styleObject" size="23" left='') {{pinStatus}}
				span.pinText(:style="styleObject") {{pinText}}
		v-card
			v-card-title {{getDialogTitle}}
				span.headline 
			v-card-actions.pa-8
				v-spacer
				v-btn(color="grey darken-2"  @click='closeDialog') {{$t('common.cancel')}}
				v-btn.defult-btn-color( @click='saveCurrentRouter') {{$t('common.confirm')}}
</template>
<script>
import { mapActions,  mapGetters } from 'vuex';
import { updateOperatorPin } from '@/api/Operator';
import { mdiPinOutline } from '@mdi/js';
import { mdiPin } from '@mdi/js';
export default {
	props:['getRoute'],
	data() {
		return {
			dialog: false,
			disabled: true,
			pinStatus: mdiPinOutline,
			pinText: this.$t('common.pinThisPage'),
			styleObject: {
				color: '#60a7d1',
				fontWeight: 600
			}
		};
	},
	mounted(){
		this.getCurrentPinStatus();
	},
	methods: {
		...mapActions(['setOperatorDefaultRouter', 'setOperatorDefaultRouterTab']),
		async saveCurrentRouter() {		
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');		
			if (this.$route.name !== operatorDefaultRouter[0] || 
			(this.$route.name === operatorDefaultRouter[0] && this.getOperatorDefaultRouterTab !== operatorDefaultRouter[1])) {
				let currentTab = this.getOperatorDefaultRouterTab;
				this.setOperatorDefaultRouter(`${this.$route.name},${currentTab}`);
				let reqData = {
					indexPin: this.getOperatorDefaultRouter,
					operatorAC: this.getOperator.operatorName
				};
				await updateOperatorPin(reqData);
				this.getCurrentPinStatus();
				this.dialog = false;
			}else{
				this.cancelCurrentPinned();
			}
		},
		getCurrentPinStatus() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOperatorDefaultRouterTab === operatorDefaultRouter[1]) {
				this.pinStatus = mdiPin;
				this.pinText = this.$t('common.pinned');
				this.styleObject = {
					color: '#60a7d1',
					fontWeight: 600
				};
			} else {
				this.pinStatus = mdiPinOutline;
				this.pinText = this.$t('common.pinThisPage');
				this.styleObject = {
					color: '#FFFFFF',
					fontWeight: 400
				};
			}
		},
		async cancelCurrentPinned(){
			this.setOperatorDefaultRouter('Dashboard');
			let reqData = {
				indexPin: this.getOperatorDefaultRouter,
				operatorAC: this.getOperator.operatorName
			};
			await updateOperatorPin(reqData);
			this.getCurrentPinStatus();
			this.setOperatorDefaultRouterTab('daily');
			this.dialog = false;
		},
		closeDialog() {
			this.dialog = false;
		},
		openDialog() {
			this.dialog = true;
		}
	},
	watch: {
		getRoute(){
			this.getCurrentPinStatus();
		},
		getOperatorDefaultRouterTab(){
			this.getCurrentPinStatus();

		}
	},
	computed: {
		getDialogTitle(){
			if(this.pinText === this.$t('common.pinned')){
				return this.$t('common.cancleCurrentPin');
			}else {
				return this.$t('common.saveCurrentPin');
			}
		},
		...mapGetters(['getOperatorDefaultRouter', 'getOperator', 'getOperatorDefaultRouterTab'])
	},
};
</script>
