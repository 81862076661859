// export const SIDEBAR_TYPE = 'SIDEBAR_TYPE';
// export const DEFAULT_THEME = 'DEFAULT_THEME';
// export const DEFAULT_LAYOUT_MODE = 'DEFAULT_LAYOUT_MODE';
// export const DEFAULT_COLOR = 'DEFAULT_COLOR';
// export const DEFAULT_COLOR_WEAK = 'DEFAULT_COLOR_WEAK';
// export const DEFAULT_FIXED_HEADER = 'DEFAULT_FIXED_HEADER';
// export const DEFAULT_FIXED_SIDEMENU = 'DEFAULT_FIXED_SIDEMENU';
// export const DEFAULT_FIXED_HEADER_HIDDEN = 'DEFAULT_FIXED_HEADER_HIDDEN';
// export const DEFAULT_CONTENT_WIDTH_TYPE = 'DEFAULT_CONTENT_WIDTH_TYPE';
// export const DEFAULT_MULTI_TAB = 'DEFAULT_MULTI_TAB';
// export const CONTENT_WIDTH_TYPE = {
// 	Fluid: 'Fluid',
// 	Fixed: 'Fixed',
// };

// export const STYLE = 'STYLE';
// export const WORKSTATIONID = 'WORKSTATIONID';
// export const SECTIONSERIAL = 'SECTIONSERIAL';
// export const MSSESSION = 'MSSESSION';
export const LANGUAGE = 'LANGUAGE';
export const GROUPID = 'GROUPID';
export const OPERATORAC = 'OPERATORAC';
export const OPERATORID = 'OPERATORID';
export const DOMAIN = 'DOMAIN';
export const CMS = 'CMS';
export const VERSION = 'VERSION';
export const CURRENCY = 'CURRENCY';
export const CURRENCYLIST = 'CURRENCYLIST';
export const EXCHANGERATE = 'EXCHANGERATE';
export const GAMELIST = 'GAMELIST';
