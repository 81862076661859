import Vue from 'vue';
import Vuex from 'vuex';
import { state, actions, mutations } from './root';
import user from './module/user';
import permission from './module/permission';
// import app from './module/app';
import getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
		permission,
		// app,
	},
	state,
	mutations,
	actions,
	getters,
});
