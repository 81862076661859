import router from '@/router';
import store from '@/store/index';
import logout from '@/util/logout';
import { getExchangeRateList } from '@/api/Config';
import statusCode from '@/assets/constant/statusCode';

router.beforeEach(async (to, from, next) => {
	const token = window.sessionStorage.getItem('token');
	// 判斷是否從其他頁面跳轉過來
	if (to.redirectedFrom && to.redirectedFrom !== '/' && to.redirectedFrom !== '/login' && to.redirectedFrom !== '/dashboard') {
		localStorage.setItem('redirected', to.redirectedFrom);
	}
	// 判斷有要求權限的頁面檢查token
	if (token) {
		if (to.path === '/login') {
			// 進入登錄頁面
			if (store.getters.addRouters.length <= 0) {
				if (localStorage.getItem('tab') && localStorage.getItem('token')) {
					await newTabRefersh();
					if (localStorage.getItem('redirected')) {
						next(localStorage.getItem('redirected'));
						localStorage.removeItem('redirected');
					} else {
						next('dashboard');
					}
				} else {
					// 同步sessionStorage至vuex中
					await refershData();
					next('dashboard');
				}
			} else {
				next('dashboard');
			}
		} else {
			// 正常流程
			if (store.getters.addRouters.length <= 0) {
				await refershData();
			}
			if (localStorage.getItem('tab')) {
				localStorage.removeItem('token');
				localStorage.removeItem('operator');
				localStorage.removeItem('router');
				localStorage.removeItem('domain');
				localStorage.removeItem('gameList');
				localStorage.removeItem('currencyList');
				localStorage.removeItem('cms');
			}
			next();
		}
	} else if (to.path === '/') {
		localStorage.removeItem('tab');
		window.sessionStorage.clear();
		next('login');
	} else {
		next();
	}
});

async function refershData() {
	try {
		store.dispatch('generateRoutes', JSON.parse(window.sessionStorage.getItem('router')));
		for (let i of store.getters.addRouters) {
			router.addRoute(i);
		}
		store.dispatch('setDomain', JSON.parse(window.sessionStorage.getItem('domain')));
		store.dispatch('setCms', JSON.parse(window.sessionStorage.getItem('cms')));
		store.dispatch('setGameList', JSON.parse(window.sessionStorage.getItem('gameList')));
		store.dispatch('setCurrencyList', JSON.parse(window.sessionStorage.getItem('currencyList')));
		const operator = JSON.parse(window.sessionStorage.getItem('operator'));
		store.dispatch('setOperator', operator);
		const exchangeRateList = await getExchangeRateList({
			reqData: { operatorID: parseInt(operator.operatorID) }
		});
		if (exchangeRateList.status === statusCode.STATUS_OK) {
			store.dispatch('setExchangeRate', JSON.parse(JSON.stringify(exchangeRateList.result.res)));
		}
	}
	catch (e) {
		logout();
	}
}

async function newTabRefersh() {
	try {
		store.dispatch('generateRoutes', JSON.parse(localStorage.getItem('router')));
		for (let i of store.getters.addRouters) {
			router.addRoute(i);
		}
		store.dispatch('setDomain', JSON.parse(localStorage.getItem('domain')));
		store.dispatch('setCms', JSON.parse(localStorage.getItem('cms')));
		store.dispatch('setGameList', JSON.parse(localStorage.getItem('gameList')));
		store.dispatch('setCurrencyList', JSON.parse(localStorage.getItem('currencyList')));
		const operator = JSON.parse(localStorage.getItem('operator'));
		store.dispatch('setOperator', operator);
		if (operator) {
			store.dispatch('setOperatorDefaultRouter', operator.indexPin);
			let routerTab = operator.indexPin.split(',');
			store.dispatch('setOperatorDefaultRouterTab', routerTab[1]);
			const exchangeRateList = await getExchangeRateList({
				reqData: { operatorID: parseInt(operator.operatorID) }
			});
			if (exchangeRateList.status === statusCode.STATUS_OK) {
				store.dispatch('setExchangeRate', JSON.parse(JSON.stringify(exchangeRateList.result.res)));
			}
		}
		localStorage.removeItem('token');
	}
	catch (e) {
		logout();
	}
}
