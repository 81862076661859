import vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from '../locale/en.json';
import twLocale from '../locale/tw.json';
import zhHant from 'vuetify/es5/locale/zh-Hant';
import en from 'vuetify/es5/locale/en';

vue.use(VueI18n);

const messages = {
	en: {
		...enLocale,
		...en

	},
	tw: {
		...twLocale,
		...zhHant
	},
};

const language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: language, // set locale
	messages, // set locale messages
	silentTranslationWarn: true,
});

export default i18n;