import Vue from 'vue';
import Router from 'vue-router';
import { publicRoute } from './config';

// const routes = publicRoute.concat(protectedRoute);

Vue.use(Router);
const router = new Router({
	// mode: 'hash',
	mode: 'history',
	// linkActiveClass: 'active',
	scrollBehavior: () => ({ y: 0 }),
	routes: publicRoute
});

export default router;
