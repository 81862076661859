<template lang='pug'>
v-app-bar.main-bg-img(fixed, dark)
	v-btn.drawerIcon(@click.stop='handleDrawerToggle', icon, large, text, :ripple='false')
		i.material-icons.drawer-icon-default(:class='getDrawerStatus ? "drawer-close" : "drawer-open"') sort
		i.material-icons.drawer-icon-default(:class='getDrawerStatus ? "drawer-open" : "drawer-close"') clear
	v-spacer.drawer-title-space
	v-toolbar-title.tool-title
		router-link(to='/dashboard', style='text-decoration: none; color: white') CARS
	v-spacer
	v-toolbar-items
		v-menu(offset-y, :nudge-bottom='5')
			template(v-slot:activator='{ on }')
				v-btn.settingIcon.px-2(icon, text, v-on='on', :ripple='false')
					i.fas.fa-cog
			v-list.pa-0
				v-list-item(ripple='ripple', @click='goSetting')
					v-list-item-action
						v-icon settings_applications
					v-list-item-content
						v-list-item-title {{ $t("menu.setting") }}
				v-list-item(ripple='ripple', @click='handleLogout')
					v-list-item-action
						v-icon exit_to_app
					v-list-item-content
						v-list-item-title {{ $t("login.logOut") }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import statusCode from '@/assets/constant/statusCode';
import { operatorLogout } from '@/api/Operator';

export default {
	name: 'AppToolbar',
	deta() {
		return {
			drawerStatus: '',
		};
	},
	mounted() {
		this.drawerStatusChange();
	},
	methods: {
		...mapActions(['setDrawerStatus']),
		handleDrawerToggle() {
			this.drawerStatus = !this.drawerStatus;
			this.setDrawerStatus(this.drawerStatus);
		},
		goHome() {
			this.$router.push({ path: '/dashboard' });
		},
		goSetting() {
			this.$router.push({ path: '/setting' });
		},
		drawerStatusChange() {
			this.drawerStatus = this.getDrawerStatus;
		},
		async handleLogout() {
			//handle logout
			const reqData = {
				operatorID: this.getOperator.operatorID,
			};
			const res = await operatorLogout(reqData);
			if (res.status === statusCode.STATUS_OK) {
				localStorage.removeItem('tab');
				localStorage.removeItem('token');
				window.sessionStorage.clear();
				localStorage.setItem('logout', true);
				window.location.reload();
			}
		},
	},
	computed: {
		...mapGetters(['getDrawerStatus', 'getOperator']),
	},
	watch: {
		getDrawerStatus() {
			this.drawerStatusChange();
		},
	},
};
</script>
