<template lang='pug'>
v-app#inspire.app.dashboard
	app-drawer.app--drawer
	app-toolbar.app--toolbar
	v-main
		// Page Header
		page-header(v-if='currentRouterPath')
		.page-wrapper.mx-0
			router-view(@emitsavePath='savePath')
		// App Footer
</template>

<script>
import _ from 'lodash';
import AppDrawer from '@/components/AppDrawer';
import AppToolbar from '@/components/AppToolbar';
import PageHeader from '@/components/PageHeader';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		'app-drawer': AppDrawer,
		'app-toolbar': AppToolbar,
		'page-header': PageHeader,
	},
	computed: {
		...mapGetters(['getBreadShow', 'getOperatorDefaultRouter']),
	},
	data() {
		return {
			windowsWidth: '',
			showDrawer: '',
			currentRouterPath: true,
		};
	},
	methods: {
		...mapActions(['setDrawerStatus', 'setBreadShow', 'setOperatorDefaultRouter']),
		savePath(path) {
			let lastSeachPage = [];
			let content = {
				path: path,
			};
			if (localStorage.getItem('lastSeachPage')) {
				lastSeachPage = JSON.parse(localStorage.getItem('lastSeachPage'));
				if (_.filter(lastSeachPage, { path: path }).length !== 0) {
					lastSeachPage = _.reject(lastSeachPage, { path: path });
				} else if (lastSeachPage.length >= 5) {
					lastSeachPage.pop();
				}
			}
			lastSeachPage.unshift(content);
			localStorage.setItem('lastSeachPage', JSON.stringify(lastSeachPage));
		},
		handleResize() {
			this.windowsWidth = window.innerWidth;
			if (this.windowsWidth >= 1264) {
				this.showDrawer = true;
			} else {
				this.showDrawer = false;
			}
			this.setDrawerStatus(this.showDrawer);
		},
	},
	created() {
		this.$vuetify.theme.dark = true;
	},
	mounted() {
		this.handleResize();
		if (this.getOperatorDefaultRouter === 'Dashboard' || this.getOperatorDefaultRouter === '') {
			this.currentRouterPath = false;
		}
	},
	watch: {
		'$route.path': function (val) {
			if (val === '/dashboard' || val === '/setting/accountsetting') {
				this.currentRouterPath = false;
				this.setBreadShow(false);
			} else {
				this.currentRouterPath = true;
				this.setBreadShow(true);
			}
		},
	},
};
</script>
