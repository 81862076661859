const user = {
	state: {
		token: '',
		operator: [],
		operatorDefaultRouter: '',
		operatorDefaultRouterTab: '',
		openAutoSearch: true,
		// version: '',
		drawerStatus: false,
		breadShow: false,
		dialogShow: false,
		statusDialog: ''
	},
	mutations: {
		SET_TOKEN: (state, token) => {
			const localState = state;
			localState.token = token;
		},
		SET_OPERATOR: (state, operator) => {
			const localState = state;
			localState.operator = operator;
		},
		SET_OPERATORDEFAULTROUTER: (state, operatorDefaultRouter) => {
			const localState = state;
			localState.operatorDefaultRouter = operatorDefaultRouter;
		},
		SET_OPERATORDEFAULTROUTERTAB: (state, operatorDefaultRouterTab) => {
			const localState = state;
			localState.operatorDefaultRouterTab = operatorDefaultRouterTab;
		},
		SET_OPENAUTOSEARCH: (state, openAutoSearch) => {
			const localState = state;
			localState.openAutoSearch = openAutoSearch;
		},
		// SET_VERSION: (state, version) => {
		// 	const localState = state;
		// 	localState.version = version;
		// },
		SET_DRAWERSTATUS: (state, drawerStatus) => {
			const localState = state;
			localState.drawerStatus = drawerStatus;
		},
		SET_BREADSHOW: (state, breadShow) => {
			const localState = state;
			localState.breadShow = breadShow;
		},
		SET_DIALOGSHOW: (state, dialogShow) => {
			const localState = state;
			localState.dialogShow = dialogShow;
		},
		SET_STATUSDIALOG: (state, statusDialog) => {
			const localState = state;
			localState.statusDialog = statusDialog;
		},		
	},
	actions: {
		setToken({ commit }, token) {
			commit('SET_TOKEN', token);
		},
		setOperator({ commit }, operator) {
			commit('SET_OPERATOR', operator);
		},
		setOperatorDefaultRouter({ commit }, operatorDefaultRouter) {
			commit('SET_OPERATORDEFAULTROUTER', operatorDefaultRouter);
		},
		setOperatorDefaultRouterTab({ commit }, operatorDefaultRouterTab) {
			commit('SET_OPERATORDEFAULTROUTERTAB', operatorDefaultRouterTab);
		},
		setOpenAutoSearch({ commit }, openAutoSearch) {
			commit('SET_OPENAUTOSEARCH', openAutoSearch);
		},
		// setVersion({ commit }, version) {
		// 	commit('SET_VERSION', version);
		// },
		setDrawerStatus({ commit }, drawerStatus) {
			commit('SET_DRAWERSTATUS', drawerStatus);
		},
		setBreadShow({ commit }, breadShow) {
			commit('SET_BREADSHOW', breadShow);
		},
		setDialogShow({ commit }, dialogShow) {
			commit('SET_DIALOGSHOW', dialogShow);
		},
		setStatusDialog({ commit }, statusDialog) {
			commit('SET_STATUSDIALOG', statusDialog);
		},
	}
};

export default user;
