<template>
  <v-app class="primary split-bg">
    <v-main>
      <v-container fluid fill-height>
        <v-layout justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <router-view :key="$route.path"></router-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
	data: () => ({}),
	created() {
		this.$vuetify.theme.dark = true;
	},
	methods: {}
};
</script>

