import { AuthLayout, DefaultLayout} from '../components/layouts';
import i18n from '../locale';
// const proImport = require('./_import_production');

export const publicRoute = [
	{ path: '*', redirect:'/', hidden: true },
	{
		path: '/',
		component: AuthLayout,
		meta: { title: 'Login' },
		redirect: '/login',
		hidden: true,
		children: [
			{
				path: 'login',
				name: 'login',
				meta: { title: 'Login' },
				component: () => import(/* webpackChunkName: "login" */ '../views/Login/Login.vue'),
			},
		],
	},
	{
		path: '/403',
		name: 'Forbidden',
		hidden: true,
		meta: { title: 'Access Denied', hiddenInMenu: true },
		component: () => import(/* webpackChunkName: "error-403" */ '../views/error/Deny.vue'),
	},
	{
		path: '/404',
		name: '404',
		hidden: true,
		meta: { title: 'Not Found' },
		component: () => import(/* webpackChunkName: "errors-404" */ '../views/error/NotFound.vue'),
	},
	{
		path: '/500',
		name: '500',
		hidden: true,
		meta: { title: 'Server Error' },
		component: () => import(/* webpackChunkName: "errors-500" */ '../views/error/Error.vue'),
	},
];

export const protectedRoute = [
	{
		path: '/dashboard',
		component: DefaultLayout,
		meta: { title: 'Home', group: 'apps', icon: '' },
		redirect: '/dashboard',
		hidden: true,
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				meta: { title: 'Home', group: 'apps', icon: 'dashboard' },
				component: () => import(/* webpackChunkName: "dashboard" */ '../views/index.vue'),
			},
		],
	},
	{
		path: '/setting',
		component: DefaultLayout,
		meta: { title: 'Setting', group: 'apps', icon: '' },
		redirect: '/setting/accountsetting',
		hidden: true,
		children: [
			{
				path: '/setting/accountsetting',
				name: 'Setting',
				meta: { title: i18n.t('menu.setting'), group: 'apps', icon: 'Setting' },
				component: () => import(/* webpackChunkName: "dashboard" */ '../views/Setting/index.vue'),
			},
		],
	},
	{
		path: '/maintenance',
		component: DefaultLayout,
		meta: { title: i18n.t('menu.operationManagement'), icon: 'person_outline', group: 'operator', itemIdx: 1 },
		redirect: '/maintenance/operator',
		children: [
			{
				path: '/maintenance/operator',
				name: 'Operator Maintenance',
				meta: {
					parentTitle: i18n.t('menu.operationManagement'),
					title: i18n.t('menu.operatorMaintenance'),
					itemIdx: 2,
				},
				component: () => import('../views/OperationManagement/OperatorMaintenance/index.vue'),
			},
			{
				path: '/maintenance/group',
				name: i18n.t('menu.groupMaintenance'),
				meta: {
					parentTitle: i18n.t('menu.operationManagement'),
					title: i18n.t('menu.groupMaintenance'),
					itemIdx: 3,
				},
				component: () => import('../views/OperationManagement/GroupMaintenance/index.vue'),
			}
		]
	},
	{
		path: '/provider',
		component: DefaultLayout,
		meta: { title: i18n.t('menu.ocmsDataMonitor'), icon: 'cloud_queue', group: 'ocmsDataMonitor', itemIdx: 70000 },
		redirect: '/provider/dashboard',
		children: [
			{
				path: '/provider/date',
				name: 'ocms operate site date',
				meta: {
					parentTitle: i18n.t('menu.ocmsDataMonitor'),
					title: i18n.t('menu.siteDashboard'),
					itemIdx: 70101,
				},
				component: () => import('../views/OCMS/Provider/Today/index.vue'),
			},
			{
				path: '/provider/trend',
				name: 'ocms operate site trend',
				meta: {
					parentTitle: i18n.t('menu.ocmsDataMonitor'),
					title: i18n.t('menu.siteTrend'),
					itemIdx: 70102,
				},
				component: () => import('../views/OCMS/Provider/SiteTrend/index.vue'),
			},
			{
				path: '/provider/market',
				name: 'ocms operate market trend',
				meta: {
					parentTitle: i18n.t('menu.ocmsDataMonitor'),
					title: i18n.t('menu.marketTrend'),
					itemIdx: 70103,
				},
				component: () => import('../views/OCMS/Provider/MarketTrend/index.vue'),
			},
		]
	},

	{
		path: '/house',
		component: DefaultLayout,
		meta: { title: i18n.t('menu.ocmsHouseRoot'), icon: 'supervisor_account', groups: 'house', itemIdx: 80100 },
		redirect: '/house/siteRecap',
		children: [
			{
				path: '/house/siteRecap',
				name: 'ocms house site recap',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.siteRecap'),
					itemIdx: 80102,
				},
				component: () => import('../views/OCMS/House/SiteRecap/index.vue'),
			},
			{
				path: '/house/memberTop',
				name: 'ocms house member top',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.memberTop'),
					itemIdx: 80103,
				},
				component: () => import('../views/OCMS/House/MemberTop/index.vue'),
			},
			{
				path: '/house/memberReg',
				name: 'ocms house member reg',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.memberReg'),
					itemIdx: 80104,
				},
				component: () => import('../views/OCMS/House/MemberReg/index.vue'),
			},
			{
				path: '/house/memberCvt',
				name: 'ocms house member cvt',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.memberCvt'),
					itemIdx: 80105,
				},
				component: () => import('../views/OCMS/House/MemberCvt/index.vue'),
			},
			{
				path: '/house/lookup',
				name: 'ocms house member lookup',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.ocmsMemberLookup'),
					itemIdx: 80106,
				},
				component: () => import('../views/OCMS/House/MemberLookup/index.vue'),
			},
			{
				path: '/house/single',
				name: 'ocms house single member',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.ocmsCustomerSingle'),
					itemIdx: 80107,
				},
				component: () => import('../views/OCMS/House/MemberSearch/index.vue'),
			},
			{
				path: '/house/agentCompare',
				name: 'ocms house agent compare',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.ocmsAgentCompare'),
					itemIdx: 80108,
				},
				component: () => import('../views/OCMS/House/AgentCompare/index.vue'),
			},
			{
				path: '/house/promo/entire',
				name: 'ocms house promo entire',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.ocmsPromoEntire'),
					itemIdx: 80110,
				},
				component: () => import('../views/OCMS/House/PromoRecap/index.vue'),
			},
			{
				path: '/house/promoCompare',
				name: 'ocms house promo compare',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.ocmsPromoCompare'),
					itemIdx: 80111,
				},
				component: () => import('../views/OCMS/House/PromoCompare/index.vue'),
			},
			{
				path: '/house/date',
				name: 'ocms house site date',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.siteDateHouse'),
					itemIdx: 80201,
				},
				component: () => import('../views/OCMS/House/Today/index.vue'),
			},
			{
				path: '/house/trend',
				name: 'ocms house site trend',
				meta: {
					parentTitle: i18n.t('menu.ocmsHouseRoot'),
					title: i18n.t('menu.siteTrendHouse'),
					itemIdx: 80202,
				},
				component: () => import('../views/OCMS/House/SiteTrend/index.vue'),
			},
		]
	},
	{
		path: '/onlineGeneral/general',
		component: DefaultLayout,
		meta: { title: i18n.t('menu.ocmsGeneral'), icon: 'insert_drive_file', group: 'onlineGeneral', itemIdx: 90000 },
		redirect: '/onlineGeneral/general/bankAccountung',
		children: [
			{
				path: '/onlineGeneral/general/bankAccountung',
				name: 'onlineGeneral operate site bankAccountung',
				meta: {
					parentTitle: i18n.t('menu.ocmsGeneral'),
					title: i18n.t('menu.bankAccounting'),
					itemIdx: 90001,
				},
				component: () => import('../views/OCMS/General/BankAccounting/index.vue'),
			},
			{
				path: '/onlineGeneral/general/serviceBanking',
				name: 'ocms operate site serviceBanking',
				meta: {
					parentTitle: i18n.t('menu.ocmsGeneral'),
					title: i18n.t('menu.serviceBanking'),
					itemIdx: 90002,
				},
				component: () => import('../views/OCMS/General/ServiceBanking/index.vue'),
			},
			{
				path: '/onlineGeneral/general/serviceCustomer',
				name: 'ocms operate site serviceCustomer',
				meta: {
					parentTitle: i18n.t('menu.ocmsGeneral'),
					title: i18n.t('menu.serviceCustomer'),
					itemIdx: 90003,
				},
				component: () => import('../views/OCMS/General/ServiceCustomer/index.vue'),
			},
			{
				path: '/onlineGeneral/general/channel',
				name: 'ocms operate site channel',
				meta: {
					parentTitle: i18n.t('menu.ocmsGeneral'),
					title: i18n.t('menu.channel'),
					itemIdx: 90004,
				},
				component: () => import('../views/OCMS/General/Channel/index.vue'),
			},
			{
				path: '/onlineGeneral/general/memberActivity',
				name: 'ocms operate site memberActivity',
				meta: {
					parentTitle: i18n.t('menu.ocmsGeneral'),
					title: i18n.t('menu.memberActivity'),
					itemIdx: 90005,
				},
				component: () => import('../views/OCMS/General/MemberActivity/index.vue'),
			},
			{
				path: '/onlineGeneral/general/memberDeposit',
				name: 'ocms operate site memberDeposit',
				meta: {
					parentTitle: i18n.t('menu.ocmsGeneral'),
					title: i18n.t('menu.memberDeposit'),
					itemIdx: 90006,
				},
				component: () => import('../views/OCMS/General/MemberDeposit/index.vue'),
			},
		]
	},

	{ path: '*', redirect: '/404', hidden: true },
];
