import './util/permission';
import Vue from 'vue';
import * as echarts from 'echarts/core';
import * as utils from '../src/util/format';
import VueMoment from 'vue-moment';
import { ValidationProvider } from 'vee-validate';
import './util/vee-validate';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'tabulator-tables/dist/css/tabulator_midnight.min.css';
import vuetify from './plugins/vuetify';
import i18n from './locale';
import App from './App.vue';
import router from './router';
import store from './store';
// import './registerServiceWorker';
import Router from 'vue-router';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueTabulator from 'vue-tabulator';

import 'swiper/css/swiper.css';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
import { BarChart, FunnelChart, HeatmapChart, LineChart, PieChart, SankeyChart } from 'echarts/charts';
import {
	BrushComponent,
	DatasetComponent,
	DataZoomComponent,
	DataZoomInsideComponent,
	DataZoomSliderComponent,
	GridComponent,
	GridSimpleComponent,
	GraphicComponent,
	MarkLineComponent,
	MarkPointComponent,
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	LegendComponent,
	LegendScrollComponent,
	LegendPlainComponent,
	VisualMapComponent
} from 'echarts/components';
echarts.use([
	CanvasRenderer, SVGRenderer,
	BarChart, FunnelChart, HeatmapChart, LineChart, PieChart, SankeyChart,
	BrushComponent,
	DatasetComponent,
	DataZoomComponent,
	DataZoomInsideComponent,
	DataZoomSliderComponent,
	GridComponent,
	GridSimpleComponent,
	GraphicComponent,
	MarkLineComponent,
	MarkPointComponent,
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	LegendComponent,
	LegendScrollComponent,
	LegendPlainComponent,
	VisualMapComponent
]);

Vue.config.productionTip = false;
Vue.use(VueMoment);
Vue.use(VueAwesomeSwiper);
Vue.use(VueTabulator);
Vue.component(ValidationProvider);
Vue.filter('commaFormatter', value => utils.commaFormatter(value));

// window.localStorage.setItem('loginTime', Date.now().toString());
if (!sessionStorage.length && !localStorage.getItem('logout')) {
	window.localStorage.setItem('loginTime', new Date().toLocaleString());
}
window.addEventListener('storage', function (event) {
	if (!event.newValue) {
		return;
	}
	if (event.key === 'loginTime') {
		if (!localStorage.getItem('logout')) {
			localStorage.setItem('token', sessionStorage.getItem('token'));
			localStorage.setItem('operator', sessionStorage.getItem('operator'));
			localStorage.setItem('router', sessionStorage.getItem('router'));
			localStorage.setItem('domain', sessionStorage.getItem('domain'));
			localStorage.setItem('gameList', sessionStorage.getItem('gameList'));
			localStorage.setItem('currencyList', sessionStorage.getItem('currencyList'));
			localStorage.setItem('cms', sessionStorage.getItem('cms'));
		}
	}
	if (event.key === 'token') {
		sessionStorage.setItem('token', event.newValue);
		// localStorage.removeItem('token');
	}
	if (event.key === 'operator') {
		sessionStorage.setItem('operator', event.newValue);
		// localStorage.removeItem('operator');
	}
	if (event.key === 'domain') {
		sessionStorage.setItem('domain', event.newValue);
		// localStorage.removeItem('domain');
	}
	if (event.key === 'gameList') {
		sessionStorage.setItem('gameList', event.newValue);
		// localStorage.removeItem('gameList');
	}
	if (event.key === 'currencyList') {
		sessionStorage.setItem('currencyList', event.newValue);
		// localStorage.removeItem('currencyList');
	}
	if (event.key === 'cms') {
		sessionStorage.setItem('cms', event.newValue);
		// localStorage.removeItem('cms');
	}
	if (event.key === 'router') {
		sessionStorage.setItem('router', event.newValue);
		// localStorage.removeItem('router');
	}
	if (event.key === 'logout') {
		window.sessionStorage.clear();
		// localStorage.removeItem('logout');
		// localStorage.removeItem('tab');
		// localStorage.removeItem('token');
		window.location.reload();
	}
});

new Vue({
	vuetify,
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app');

// 避免Router重複點擊錯誤
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error);
};
