import fetch from '../util/fetch';

// 取得匯率表
export function getExchangeRateList(reqData){
	return fetch({
		url: '/cars/exchangerate/list',
		method: 'post',
		data: {
			reqData,
		},
	});
}

export function openOTP(reqData){
	return fetch({
		url: '/cars/config/item/'+ reqData.operatorID + '/otp',
		method: 'post',
		data: {
			reqData,
		},
	});
}

export function verifyOTP(reqData){
	return fetch({
		url: '/cars/config/item/'+ reqData.operatorID + '/v/otp',
		method: 'post',
		data: {
			reqData,
		},
	});
}

export function closeOTP(reqData){
	return fetch({
		url: '/cars/config/item/'+ reqData.operatorID + '/c/otp',
		method: 'post',
		data: {
			reqData,
		},
	});
}