import { publicRoute, protectedRoute } from '../../router/config';

const permission = {
	state: {
		routers: publicRoute,
		addRouters: [],
	},
	mutations: {
		SET_ROUTERS: (state, routers) => {
			const localState = state;
			localState.addRouters = routers;
			localState.routers = publicRoute.concat(routers);
		},
	},
	actions: {
		generateRoutes({ commit }, data) {
			return new Promise((resolve) => {
				const accessedRouters = filterAsyncRouter(protectedRoute, data);
				commit('SET_ROUTERS', accessedRouters);
				resolve();
			});
		},
	}
};

/**
 * 遞歸過濾異步路由表，返回符合用戶角色權限的路由表
 * @param protectedRoute
 * @param roles
 */
function filterAsyncRouter(tempRouterMap, menus) {
	const localMenus = menus;
	const accessedRouters = tempRouterMap.filter((route) => {
		const localRoute = route;
		if (hasPermission(localMenus, localRoute)) {
			if (route.children && route.children.length) {
				localRoute.children = filterAsyncRouter(route.children, localMenus);
			}
			return true;
		}
		return false;
	});
	return accessedRouters;
}

/**
 * 通過meta.role判斷是否與當前用戶權限匹配
 * @param roles
 * @param route
 */
function hasPermission(menus, route) {
	let isAuth = true;
	if (route.meta) {
		if (route.meta.itemIdx) {
			isAuth = menus.some(menu => route.meta.itemIdx === menu.itemIdx);
		}
		if (route.meta.reportIdx) {
			isAuth = menus.some(menu => route.meta.reportIdx === menu.reportIdx);
		}
	}
	return isAuth;
}

export default permission;
