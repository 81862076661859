<template lang='pug'>
v-navigation-drawer.app--drawer(
	:mini-variant.sync='mini',
	app,
	v-model='hasShowDrawer',
	:width='drawWidth',
	overlay-opacity='0.7',
	touchless
)
	v-list(dense, expand)
		v-list-item.menu-bgc(@click.stop='mini = !mini', ripple, color='secondary')
			v-list-item-action
				i.material-icons.pr-2.font-vertical-middle {{ mini ? "chevron_right" : "chevron_left" }}
			v-list-item-content
				v-list-item-title
		v-divider
		template(v-for='item in menus')
			// group with subitems
			v-list-group.mb-2(
				v-if='item.items',
				v-model='item.active',
				:key='item.title',
				:group='item.group',
				:prepend-icon='item.icon',
				no-action,
				@click='close',
				color='white'
			)
				v-list-item.pa-0(slot='activator', ripple, color='secondary')
					v-list-item-content
						v-list-item-title
							span {{ item.title }}
				template(v-for='subItem in item.items')
					// sub group
					v-list-group(v-if='subItem.items', :key='subItem.name', :group='subItem.group', sub-group='sub-group')
						v-list-item(slot='activator', ripple)
							v-list-item-content
								v-list-item-title {{ subItem.title }}
						v-list-item(v-for='grand in subItem.children', :key='grand.name', :to='genChildTarget(item, grand)', :href='grand.href', ripple)
							v-list-item-content
								v-list-item-title {{ grand.title }}
					// child item
					v-list-item.menu-bgc(
						v-else,
						:key='subItem.name',
						:to='genChildTarget(item, subItem)',
						:href='subItem.href',
						:disabled='subItem.disabled',
						:target='subItem.target',
						ripple,
						color='secondary'
					)
						v-list-item-content
							v-list-item-title
								span.sec-font-color {{ subItem.title }}
						v-list-item-action(v-if='subItem.action')
							v-icon(:class='[subItem.actionClass || "success--text"]') {{ subItem.action }}
			//- v-subheader.pl-4(v-else-if='item.header' :key='item.name') 
			//- 	i.material-icons {{ item.header }}
			//- v-divider(v-else-if='item.divider' :key='item.name')
			//- // top-level link
			//- v-list-item(v-else :to='!item.href ? { name: item.name } : null' :href='item.href' ripple :disabled='item.disabled' :target='item.target' rel='noopener' :key='item.name')
			//- 	v-list-item-action(v-if='item.icon')
			//- 		v-icon {{ item.icon }}
			//- 	v-list-item-content
			//- 		v-list-item-title {{ item.title }}
			//- 	v-list-item-action(v-if='item.subAction')
			//- 		v-icon.success--text {{ item.subAction }}
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// import menu from '@/api/menu'
export default {
	name: 'AppDrawer',
	props: {
		drawWidth: {
			type: [Number, String],
			default: '260',
		},
	},
	data() {
		return {
			windowsWidth: '',
			mini: true,
			menus: [],
			hasShowDrawer: false,
		};
	},
	computed: {
		...mapGetters(['permission_routers', 'getDrawerStatus']),
	},
	created() {
		this.createRouter();
	},
	mounted() {
		this.drawerStatus();
	},
	methods: {
		...mapActions(['setDrawerStatus']),
		genChildTarget(item, subItem) {
			if (subItem.href) return;
			if (subItem.component) {
				return {
					name: subItem.component,
				};
			}
			// return { name: `${item.group}/${subItem.name}` };
		},
		createRouter() {
			const Menu = [];
			// eslint-disable-next-line no-undef
			for (const i of this.permission_routers) {
				if (!i.hidden) {
					if (i.meta.group === 'ocms') {
						Menu.push({ header: '' });
					}
					if (i.meta.group === 'onlineGeneral') {
						Menu.push({ header: '' });
					}
					if (i.meta.group === 'ocmsDataMonitor') {
						Menu.push({ header: '' });
					}
					if (i.meta.group === 'customer') {
						Menu.push({ header: '' });
					}
					const tmpObj = {};
					tmpObj.title = i.meta.title;
					tmpObj.group = i.meta.group;
					tmpObj.icon = i.meta.icon;
					if (i.children) {
						tmpObj.items = [];
						for (const j of i.children) {
							tmpObj.items.push({
								name: j.name,
								title: j.meta.title,
								component: j.name,
							});
						}
					}
					Menu.push(tmpObj);
				}
			}
			this.menus = Menu;
		},
		goHome() {
			this.$router.push({ path: '/dashboard' });
		},
		drawerStatus() {
			this.hasShowDrawer = this.getDrawerStatus;
		},
		close() {
			this.menus.forEach((item) => {
				if (item.active) {
					return (item.active = false);
				}
			});
		},
	},
	watch: {
		getDrawerStatus() {
			this.drawerStatus();
		},
		hasShowDrawer(val) {
			this.windowsWidth = window.innerWidth;
			if (this.windowsWidth < 1264 && val !== this.getDrawerStatus) {
				this.setDrawerStatus(val);
			}
		},
	},
};
</script>

<style lang='stylus'>
.app--drawer {
  position: fixed;
  overflow: hidden;

  .drawer-menu--scroll {
    overflow: auto;
  }
}

.v-overlay--active {
  z-index: 10 !important;
}
</style>
