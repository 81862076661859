import i18n from '@/locale';

const deviceType = {
	0: 'Web',
	1: 'App',
	2: 'PWA-2',
	3: 'Line',
	4: 'PWA-4',
	5: 'N/A'
};
export default deviceType;