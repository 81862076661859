import _ from 'lodash';
import moment from 'moment';
import deviceType from '@/assets/constant/deviceType';

const default_functor = (child_elem, child_key, parent_elem, is_leaf, p_time_format) => {

	if('dim_ptt' == child_key && null != child_elem){
		parent_elem[child_key] = moment.utc(child_elem.value).format(p_time_format);
		return;
	}

	if(!is_leaf)
		return;

	if('dim_device_id' == child_key){
		parent_elem[child_key] = deviceType[child_elem];
		return;
	}

	const str_columns = [
		'dim_site_id',
		'dim_brand_id',
		'dim_brand_code',
		'dim_cy_id',
		'game_kind_name',
		'game_theme_name',
		'dim_promo_id',
		'map_ag_username',
		'dim_channel_token'
	];

	if(str_columns.includes(child_key) || typeof child_elem === 'number')
		return;

	parent_elem[child_key] = parseFloat(child_elem) || 0.0;
	// parent_elem[child_key] = parseFloat(child_elem);
};

export default function iter_all(p_obj, p_time_format = 'YYYY-MM-DD HH:mm:ss', p_functor = default_functor){
	for(const key in p_obj){
		const is_leaf = (typeof p_obj[key] !== 'object' || p_obj[key] === null);
		p_functor(p_obj[key], key, p_obj, is_leaf, p_time_format);

		if(typeof p_obj[key] === 'object' && p_obj[key] !== null)
			iter_all(p_obj[key], p_time_format, p_functor);
	}
}

// 匯率轉換
export async function rateOfExchange(res, currency, rateList) {
	// eslint-disable-next-line no-restricted-syntax
	for (const i of res) {
		// 查詢匯率表
		const cuy = rateList[i.cy_id][currency];
		const array = Object.keys(i);
		array.forEach(element => {
			if (typeof i[element] !== 'string') {
				i[element] *= cuy;
				i.cy_id = currency;
			}
		});
	}
	return res;
}
// 根據同樣key 聚集value資料
export async function transfer(arr, key, value) {
	const res = {};
	// arr.forEach(item => {
	// 	if (item['area_id'] in res){
	// 		res[item[key]].push(item[value]);
	// 		res[item[key]].sort();
	// 	} else {
	// 		res[item[key]] = {};
	// 		res[item[key]][item['area_id']] = [];
	// 	}
	// });
	arr.forEach(item => {
		if (item[key] in res){
			res[item[key]].push(item[value]);
			res[item[key]].sort();
		} else {
			res[item[key]] = [];
		}
	});
	return res;
}
// 根據同樣key 累加value資料
export function trans(arr, key, value) {
	let obj = {};
	const res = [];
	arr.forEach(item => {
		// if (obj[key]) {
		if (obj[key] === item[key]) {
			res[res.length - 1][value] += parseFloat(item[value]);
		} else {
			obj = {};
			obj[key] = item[key];
			obj[value] = parseFloat(item[value]);
			// obj.Currency = item.cy_id;
			res.push(obj);
		}
	});
	return res;
}
export async function titleSort(box, key, t){
	const arr = [];
	for(let i =0; i<box.length;i+=1){
		arr.push(box[i][key]);
	}
	const counter = {};
	arr.forEach(function(x) {
		counter[x] = (counter[x] || 0) + 1;
	});
	const val = counter[t];
	if (t === undefined) {
		return counter;
	}
	return (val) === undefined ? 0 : val;
}

export async function titleRowspan(arr, data, key){
	const dimension = Object.values(arr);
	dimension.push(0);
	const c = [0];
	for (let i = 0; i < dimension.length -1; i += 1){
		c.push(c[i] + dimension[i]);
	}
	for (let j = 0; j < c.length - 1; j += 1){
		data[c[j]][key] = dimension[j];
	}
	data.forEach(item => {
		JSON.parse(JSON.stringify(item));
	});
	return data;
}

// 根據同樣的2個key 累加value資料
export async function transData(arr, key1, key2, value){
	let obj = {};
	let array = [];
	let total = [];
	const res = [];
	const tmpArr = groupBy(arr, function(item){
		return [item[key1]];
	});
	tmpArr.forEach(item => {
		item.sort((a, b) => (a[key2] > b[key2] ? 1 : -1));
		const tmp = trans(item, key2, value);
		if(item[0][key1] !== ''){
			for(let i of tmp){
				if(i[key2]){
					obj = {};
					// obj[key1] = item[0][key1];
					obj[key2] = i[key2];
					// 負數標記
					if(i[value] < 0){
						obj.negative = true;
					}
					obj[value] = roundOff(i[value]);
					total.push(i[value]);
					array.push(obj);
				}
			}
			obj = {};
			obj[key1] = item[0][key1];
			// obj[key2] = 'Total';
			const tot = roundOff(total.reduce((total, val) => { return total + val; }, 0));
			obj[value] = tot;
			if(tot< 0){
				obj.negative = true;
			}
			obj['items'] = array;
			array = [];
			res.push(obj);
			total = [];
		}
	});
	return res;
}
export function groupBy(array , f){
	let groups = {};
	array.forEach( function( o ) {
		let group = JSON.stringify( f(o) );
		groups[group] = groups[group] || [];
		groups[group].push( o );
	});
	return Object.keys(groups).map( function( group ) {
		return groups[group];
	});
}
// 帶小數的四捨五入
export function roundDecimal(val, precision = 1) {
	if(val < 0) {
		return roundDecimal(Math.abs(val)) * -1;
	}
	// eslint-disable-next-line no-restricted-properties
	return Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, precision || 0);
}
// 整數四捨五入
export function roundOff(val, precision = 0) {
	if(val < 0) {
		return roundOff(Math.abs(val)) * -1;
	}
	// eslint-disable-next-line no-restricted-properties
	return Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, precision || 0);
}
// 數字每三位隔開
export function toThousandslsFilter(num) {
	if(num < 0) {
		return '-' + toThousandslsFilter(Math.abs(num));
	}
	return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}
// 四捨五入至小數第一位並每三位隔開
export function roundFilter(num){
	return toThousandslsFilter(roundDecimal(num));
}
// 整數四捨五入並每三位隔開
export function roundOffFilter(num){
	return toThousandslsFilter(roundOff(num));
}
// 四捨五入至小數第一位並使用百分比顯示
export function rdPercent(num){
	return roundDecimal(num * 100) + '%';
}
// 整數四捨五入並使用百分比顯示
export function rdPercentOff(num){
	return roundOff(num * 100) + '%';
}
// 整數四捨五入(不含%,百分比顯示使用)
export function rdPercentOn(num){
	return roundOff(num * 100);
}
// 四捨五入至小數第一位並使用百分比顯示 (顯示差距百分比)
export function gapPercent(num){
	if(!_.isFinite(num)) { num = 1; }
	return roundDecimal((num * 100) - 100) + '%';
}
// 四捨五入至小數第一位並使用PR值顯示
export function pRank(num){
	return roundDecimal(num * 100) + ' th';
}
// 數字格式化成K,M等格式
export function nFormatter(num, digits) {
	if(num < 0) {
		return '-' + nFormatter(Math.abs(num), digits);
	}
	const si = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'K' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e9, symbol: 'B' },
		// { value: 1e12, symbol: 'T' },
		// { value: 1e15, symbol: 'P' },
		// { value: 1e18, symbol: 'E' }
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	let i;
	for (i = si.length - 1; i > 0; i -= 1) {
		if (num >= si[i].value) {
			break;
		}
	}
	return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}
// 數字格式化成K格式
export function nFormatterToK(num, digits) {
	const si = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'K' },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	let i;
	for (i = si.length - 1; i > 0; i -= 1) {
		if (num >= si[i].value) {
			break;
		}
	}
	return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}
// domain filter
// export function domainFilter(domain, key){
// 	return domain[key];
// }

export function selectAll(list){
	return [...list];
}
//若Infinity則返回NaN
export function getNaN(num) {
	if(num == Infinity || num == -Infinity ){
		return  NaN;
	}
	return num;
}
/**
 * 轉換千分位符號格式
 * @param {UTCString} value
 * @returns {String} 回傳格式化後的千分位符號格式
 * @example
 * // 返回 9,999,999
 * commaFormatter(9999999);
 */
export function commaFormatter(value) {
	/* eslint-disable max-len */
	return value.toString().replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, (all, pre, groupOf3Digital) => pre + groupOf3Digital.replace(/\d{3}/g, ',$&'));
}

export function fetchColumns(p_ctx_data, p_ctx_group, p_mtr_name){
	return p_ctx_group.names.map((elem_1) => {
		let ret = [];
		p_ctx_data.forEach((elem_2) => {
			if(elem_1 == elem_2[p_ctx_group.column])
				ret.push(elem_2[p_mtr_name]);
		});
		return ret;
	});
}

export function computeRatio(p_ctx_data){
	let termly_sum = [];
	p_ctx_data.forEach((elem_1) => {
		elem_1.forEach((elem_2, idx_2) => {
			if(undefined == termly_sum[idx_2])
				termly_sum[idx_2] = elem_2;
			else
				termly_sum[idx_2] += elem_2;
		});
	});
	return p_ctx_data.map((elem_1) => {
		return elem_1.map((elem_2, idx_2) => {
			return elem_2 / termly_sum[idx_2] * 100;
		});
	});
}
