import _ from 'lodash';
import axios from 'axios';
import store from '@/store';
import { logger, getAuthUser } from '../util/logger';
import logout from '@/util/logout';

// 创建axios实例
const fetch = axios.create({
	baseURL: '', // api的base_url
	timeout: 60000, // 請求超過時間
});
// request 攔截器
fetch.interceptors.request.use((config) => {
	const localConfig = config;
	localConfig.requestStartTime = Date.now();
	if (window.sessionStorage.getItem('token')) {
		localConfig.headers.Authorization = `Bearer ${window.sessionStorage.getItem('token')}`;
	}
	let content = `request - "${_.toUpper(localConfig.method)}, ${localConfig.url}"`;
	if (localConfig.data) {
		const reqString = JSON.stringify(localConfig.data);
		const end = reqString.length > 200 ? '...' : '';
		content += `, ${reqString.substring(0, 200)}${end}`;
	}
	// eslint-disable-next-line no-console
	// console.log('debug', content);
	// logger('debug', content);
	return config;
}, (error) => {
	Promise.reject(error);
});

// respone 攔截器
fetch.interceptors.response.use(
	(response) => {
		const res = response.data;
		if(response.headers.authorization){
			store.dispatch('setToken', response.headers.authorization);
			window.sessionStorage.setItem('token', response.headers.authorization);
			localStorage.setItem('token', response.headers.authorization);
		}
		// const authUser = getAuthUser(response.headers.authorization);
		const responseTime = Date.now() - response.config.requestStartTime;
		// eslint-disable-next-line no-console
		// console.log('debug', `${response.config.requestStartTime}, ${response.config.url}, ${responseTime}ms`);
		// logger('debug', `response - [${response.status}] [${responseTime} ms] ${authUser} - "${response.config.method.toUpperCase()} ${response.config.url}" ${response.headers['content-length']}`);
		if (res.status.toString().startsWith(9)) {
			const title = `API status ${res.status} error. `;
			const message = `${response.request.responseURL}<br>${res.result.message}`;
			// eslint-disable-next-line no-console
			// console.log(title, message);
			// logger('error', title + message);
		} else if (res.status.toString().startsWith(8)) {
			// eslint-disable-next-line no-console
			// console.log('warn', `API status ${res.status} error: ${response.request.responseURL}: ${res.result.message}`);
			// logger('warn', `API status ${res.status} error: ${response.request.responseURL}: ${res.result.message}`);
		} else if (res.status !== 1) {
			const title = `API status ${res.status} error. `;
			const message = `${response.request.responseURL}<br>${res.result.message}`;
			// eslint-disable-next-line no-console
			// console.log(title, message);
			// logger('error', title + message);
			return Promise.reject('error');
		}
		return response.data;
	},
	(error) => {
		try {
			const title = 'API http error. ';
			const message = `${error.response.data.status}:${error.response.data.result}`;
			if (error.response.status === 401) {
				// logger('error', title + message);
				// eslint-disable-next-line no-console
				// console.log(title, message);
				logout();
			} else {
				// logger('error', title + message);
				// eslint-disable-next-line no-console
				// console.log(title, message);
			}
		} catch (e) {
			// logger('error', 'API Network error. Please check your network connection');
			// eslint-disable-next-line no-console
			// console.log('API Network error', 'Please check your network connection');
			console.log('The Server is down!');
		}
		return Promise.reject(error);
	},
);
export default fetch;
