<template lang="pug">
	.app-root
		router-view
		v-overlay(:z-index="zIndex" :value="overlay" opacity="0.9")
		// global dialog
		div.dialogBox( v-if="dialogShowStatus")
			v-card.dialog_lineHight(:color='bgColor')
				v-row
					v-img.mt-4(max-width='50' height='50' :src='imgSrc')
				v-row
					v-col.dialogContent
						p.text-center.titleSize(:class="{'bgGreen':togreen}") {{ message }}
				v-row
					v-col.dialogContent
						p.text-center.textSize(:class="{'bgGreen':togreen}") {{ text }}
				v-card-actions
					v-col.text-center.btnSpace
						v-btn.redText.small_font(small='' rounded='' color='#FFFFFF' dark
						v-if="this.message == 'error'|| this.message =='錯誤'" @click='setDialogShowFalse') {{ this.$t('error.closeBtn') }}
</template>

<script>
// import AppEvents from './event'
import { mapActions, mapGetters } from 'vuex';

export default {
	data() {
		return {
			zIndex: 1,
			overlay: false,
			rightDrawer: false,
			dialogShowStatus: false,
			imgSrc: '',
			message: '',
			text: '',
			language: 'en',
			bgColor: '',
			togreen: false
		};
	},
	computed: {
		...mapGetters(['getDialogShow', 'getStatusDialog'])
	},

	methods: {
		...mapActions(['setDialogShow']),
		statusColor(obj) {
			if (obj.statusMessage == 'error' || obj.statusMessage == '錯誤') {
				this.bgColor = '#D32F2F';
				this.togreen = false;
			} else if (obj.statusMessage == 'success' || obj.statusMessage == '成功') {
				this.bgColor = '#FFFFFF';
				this.togreen = true;
			}
			this.message = obj.statusMessage;
			this.imgSrc = obj.statusSrc;
			this.text = obj.statusText;
		},
		setDialogShowFalse() {
			this.setDialogShow(false);
			this.overlay = false;
		},
		getLanguageSetting() {
			if (!localStorage.getItem('language')) {
				localStorage.setItem('language', this.language);
			}
		}
	},
	mounted() {
		this.getLanguageSetting();
	},
	watch: {
		getDialogShow() {
			let vm = this;
			let statusDialogOBJ = JSON.parse(this.getStatusDialog);
			vm.statusColor(statusDialogOBJ);
			vm.dialogShowStatus = vm.getDialogShow;
			vm.overlay = vm.dialogShowStatus;

			if (vm.message == 'success' || vm.message == '成功') {
				setTimeout(() => {
					vm.setDialogShow(false);
				}, 2000);
			}
		}
	}
};
</script>
<style lang="scss">
@import 'styles/allview.scss';
</style>
